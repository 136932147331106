<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <view>
      <el-button type="primary" @click="showAdd">添加账号</el-button>
    </view>
    
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="站长名称" prop="agentName"></el-table-column>
      <el-table-column label="用户名" prop="userName"></el-table-column>
      <el-table-column label="手机号码" prop="phone"></el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showEdit(scope.row)"
            v-auth="this.per.AGENT_EMPLOYEE_UPDATA"
          >
            修改
          </span>
          <span
            class="option option-danger"
            @click="doDel(scope.row)"
            v-auth="this.per.AGENT_EMPLOYEE_DEL"
            >删除</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :agentList="agentList"
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submitUser"
    ></EditModal>
  </div>
</template>
  
  <script>
import { fetchAdminList, delAdmin, ZZAgentAccountAdd, ZZAgentAccountUpdate } from "@/api/zzAgent";
import EditModal from "./component/EditModal";

export default {
  components: { EditModal },
  data() {
    return {
      dataSource: [],
      query: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    // 删除用户
    doDel(e) {
      this.$confirm("是否要删除用户", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        delAdmin({ id: e.id }).then((res) => {
          this.getList();
          this.$message.success("删除成功");
        });
      });
    },
    showAdd() {
      this.modalData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },
    showEdit(data) {
      this.modalData = this.deepClone(data);
      this.modalType = "edit";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    submitUser(data) {
      let request = ZZAgentAccountUpdate;
      let msg = "编辑用户成功";
      if (this.validatenull(data.id)) {
        request = ZZAgentAccountAdd;
        msg = "添加用户成功";
        data.stationId = 0;
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.getList();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchAdminList({ ...this.query, ...this.pages }).then((res) => {
        this.dataSource = res.data.data.records || [];
        this.total = res.data.data.total;
      });
    },
  },

  mounted() {
    this.getList();
  },
};
</script>
  